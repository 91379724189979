// Owner File Information 
$ThemeName:"Ecobits";
$ThemeURI:"https://github.com/FawzyZayed/SassDemo";
$Author: "eDigits";
$AuthorURI:"edigits.net";
$Description:"Buld website for ecobits";
$version: "1.0.0";
$Tags:"eDigits,Ecobits, New , Custom , Responsive , Bootstarp , Gulp , SCSS , HTML5 , IconFont , Quality , Typography , Javascript , CSS , GoogleFont";
$TextDomain:"eDigits";

/*
    Theme Name: #{$ThemeName}
    Theme URI: #{$ThemeURI}
    Author: #{$Author}
    Author URI:#{$AuthorURI}
    Description: #{$Description}.
    Version: #{$version}
    License: GNU General Public License v2 or later
    License URI: http://www.gnu.org/licenses/gpl-2.0.html
    Tags:#{$Tags}
    Text Domain: #{$TextDomain}

    This theme, is licensed under the GPL.
    Use it to make something cool, have fun, and share what you've learned with others.
*/






.two-col{
    -moz-column-count:2;
    -moz-column-gap:20px;
    -webkit-column-count:2;
    -webkit-column-gap:20px;
    -moz-column-rule-color:#ccc;
    -moz-column-rule-style:solid;
    -moz-column-rule-width:0px;
    -webkit-column-rule-color:#ccc;
    -webkit-column-rule-style:solid;
    -webkit-column-rule-width:0px;}

@media (max-width: 768px){
    .two-col{
        -moz-column-count:1;
        -webkit-column-count:1;
    }
}
@media (max-width: 450px){ /** Mobile size Size **/
    .two-col{
        -moz-column-count:1;
        -webkit-column-count:1;
    }
}
