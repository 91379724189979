.sidebar{
    h5{
        color: $color-2;
        font-size: 17px;
    }
   .archiveSide{
       padding: 5px 0;
        li{
            border-bottom: 1px solid #f2f4f4;
            list-style: none;
            padding: 8px 0;
            color: #828f97;
            
            &:before{
                content: '\f104';
                font: normal normal normal 14px/1 FontAwesome;
                margin-left: 8px;
            }
            a{color: #828f97}
            span{
                display: inline-block;
                float: left;
                margin-top: 7px;
            }
            &:hover{
                color: $color-1;
                a{
                    color: $color-1;
                    text-decoration: none;
                }
                span{color: $color-1;}
            }
        }
    } 
    .NewsList{
        h6{
            font-size: 15px;
            color: $color-2;
        }
        p,span{
            font-size: 14px;
            color: #828f97;
        }
    }
}
