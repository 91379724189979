.InnerRelated{
    float: left;
    
    .NewsList{
        h6{
            font-size: 15px;
            color: $color-2;
        }
        p,span{
            font-size: 14px;
            color: #828f97;
        }
    }
}
.contactform{
    @include respond-to(Phones) {padding: 0 !important;margin: 0 10px !important;}
    color: #828f97;
    a{color: #828f97;}
    input,textarea{
        border: 1px solid #f1f1f1;
        border-radius: 0;
        &:focus{
            border: 1px solid #aee1d6;
        }
    }
    .btn-contact{
        border-radius: 20px;
        background: $color-2;
        color: #fff;
        padding: 5px 30px;
    }
    .btn-login{
        border-radius: 20px;
        background: #f2f6f8;
        color: $color-2;
        padding: 5px 30px;
    }
}
.mapr{
    background: url(../images/mapr.png) no-repeat right top;
    padding-right: 40px;
    color: #828f97;
}
.emailr{
    background: url(../images/emailr.png) no-repeat right top;
    padding-right: 40px;
    color: #828f97;
}
.Footersoicalr{
    text-align: right;
    a{
        display: inline-block;
        font-size: 0;
        margin-right: 5px;
        opacity: .8;
        width: 26px;
        height: 26px;
        &.facebook{background: url(../images/fsoicalr.png) no-repeat -122px 0px;}
        &.twitter{background: url(../images/fsoicalr.png) no-repeat -92px 0px; }
        &.youtube{background: url(../images/fsoicalr.png) no-repeat -60px 0px;}
        &.gplus{background: url(../images/fsoicalr.png) no-repeat -30px 0px;}
        &.instagram{background: url(../images/fsoicalr.png) no-repeat 0px 0px;}
    }
}
.loginInner{
    background: #fff;
    h3{
        background: $color-1;
        color: #fff;
        font-size: 22px;
        font-weight: normal;
    }
    
}
.liabout{
    li{
        line-height: 30px;
        font-size: 15px;
        color: #828f97;
        list-style: url(../images/listyle.png);
    }
}
.newsInfo{
    border-bottom: 1px solid #dadada;
    span{
        color: #999999;
        margin-right: 5px;
    }
    a{
        display: inline-block;
        color: #dadada;
        border: 1px solid #dadada;
        padding: 5px 7px;
        border-radius: 50%;
        width: 28px;
        height: 28px;
        &:hover{
            background: $color-2;
            color: #fff;
            border: 1px solid $color-2;
        }
    }
    
}
.pagination{
    .page-item{
        .page-link{
            background: #f1f1f1;
            color: $color-2;
            border-radius: 50%;
            border: none;
            margin: 0 3px;
            &.active{
                background: $color-2;
                color: #fff;
            }
        }
    }
}