.navMain{
    background: $color-3;
    border-bottom: 2px solid $color-1;
    .navbar{
        padding-top: 0;
        padding-bottom: 0;
        button{
            position: relative;
            color: #fff;
            padding: 10px 0;
            outline: 0;
            width: 89%;
        }
        .navbar-nav{
            padding: 0;
            .nav-item{
                margin-top: -4px;
                .nav-link{
                    padding: 1rem ;
                    color: #fff;
                    font-size: 15px;
                }
                &.dropdown{
                    &:hover{
                       .dropdown-menu {
                            display: block;
                            margin-top: 0;
                           border-radius: 0;
                           .dropdown-item{
                               color: #828f97;
                               padding: 7px 1.5rem;
                               &:hover{
                                   background: #f9f9f9;
                                   color: $color-1;
                               }
                               
                           }
                        } 
                    }
                }
                &:hover,&.active{
                    background: $color-1;
                }
            }
        }
    }
}
 