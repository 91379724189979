.slick-dots{
    text-align: center;
    position: relative;
    top: -80px;
    li{
        display: inline-block;
        button{
            outline: 0;
            font-size: 0;
            padding: 5px;
            background: transparent;
            border: 1px solid #fff;
            margin: 2px;
            border-radius: 100%;
        }
        &.slick-active{
            button{
                background: #fff;
            }
        }
    }
}
.slick-arrow{
    position: absolute;
    top: 50%;
    background: $color-2;
    font: normal normal normal 0px/1 FontAwesome;
    color: #fff;
    border-radius: 50%;
    border: 2px solid #fff;
    padding: 10px 13px;
    outline: 0;
    z-index: 1;
    
    &:focus{
        outline: 0;
    }
    &.slick-prev{
        left: 10px;
        &:before{
            content: "\f104";
            font-size: 10px;
        }
    }
    &.slick-next{
        right: 10px;
        &:before{
            content: "\f105";
            font-size: 10px;
        }
    }
}