header{
    background: #fff;
    .topheader{
        background: $color-2;
        font-size: 13px;
        @include respond-to(Phones) {text-align: center;}
        a{
            color: #fff;
            padding-top: 10px;
            display: inline-block;
            &:last-child{
                margin-right: 10px;
            }
            &:hover{
                text-decoration: none;
            }
            &.login{
                background: url(../images/user.png) no-repeat right center;
                padding: 10px 25px 10px 10px;
            }
            &.newlogin{
                background: url(../images/login.png) no-repeat right center;
                padding: 10px 27px 10px 10px;
            }
        }
        .searchSection{
            .text-left{text-align: right !important;}
            .searchTop{
                input {
                    float: left;
                    outline: none;
                    &[type=search] {
                        -webkit-appearance: textfield;
                        -webkit-box-sizing: content-box;
                        color: #fff;
                        font-size: 100%;
                        background: transparent url(../images/search.png) no-repeat right center;
                        border: solid 1px transparent;
                        padding: 9px 32px 9px 10px;
                        width: 55px;
                        border-radius: 0;
                        -webkit-transition: all .5s;
                        -moz-transition: all .5s;
                        transition: all .5s;
                        border-left: 1px solid #2e547f;
                        &:focus {
                            width: 130px;
                            border-color: transparent;
    //                        -webkit-box-shadow: 0 0 5px rgba(109,207,246,.5);
    //                        -moz-box-shadow: 0 0 5px rgba(109,207,246,.5);
    //                        box-shadow: 0 0 5px rgba(109,207,246,.5);
                        }
                    }
                    &::-webkit-search-decoration,&::-webkit-search-cancel-button {
                        display: none; 
                    }
                    &:-moz-placeholder {
                        color: #fff;
                    }
                    &::-webkit-input-placeholder {
                        color: #fff;
                    }
                }
            }
        }
    }
}


