.bgwight{
    background: #fff;
    z-index: 1;
    margin-bottom: -30px;
    margin-top: -64px;
    padding-top: 30px;
    @include respond-to(Phones) {margin-top: 0px;}
}
.HomeNew{
    h2{font-size: 24px;color: $color-2;position: relative;padding-bottom: 10px;
        &:after{
            content: '';
            height: 1px;
            width: 15px;
            background: $color-1;
            position: absolute;
            bottom: 5px;
            right: 5px;
        }
        &:before{
            content: '';
            height: 1px;
            width: 15px;
            background: $color-1;
            position: absolute;
            bottom: 3px;
            right: 12px;
        }
    }
    .NewsItems{
        border-bottom: 1px solid #f2f4f4;
        padding: 10px;
        &.firstNews{
            h4{font-size: 17px;color: $color-2;}
            h6{font-size: 16px; color: #b5b5b5;}
            p{font-size: 15px;color: #000;}
            .imgNews{
                position: relative;
                @include respond-to(Phones) {margin-bottom: 10px;}
                &:after{
                    content:'';
                    background: $color-1;
                    width: 90%;
                    height: 95%;
                    position: absolute;
                    top: -3%;
                    z-index: -1;
                    right: 2%;
                }
            }
        }
        &.listNews{
            padding-top: 20px;
            padding-bottom: 20px;
            h5{font-size: 15px;color: $color-2;}
            h6{font-size: 15px; color: #b5b5b5;}
        }
    }
    .ReadMore{
        a{
            color: $color-1;
            font-weight: bold;
        }
    }
}
.itemAbout{
    padding: 52px 0 50px;
    margin-top: 30px;
    border-top: 1px solid #f2f4f4;
    color: #828f97;
    i{
        padding: 15px 20px;
        display: inline-block;
        background: $color-2;
        border-radius: 100%;
        font-size: 20px;
        color: #fff;
        margin-bottom: 15px;
    }
    &:hover{
        background: $color-2;
        color: #fff;
        border-top: 3px solid $color-1;
        padding: 50px 0;
    }
}