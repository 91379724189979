// include Fonts 
@font-face {
  font-family: Shabnam;
  src: url('../fonts/Shabnam.eot');
  src: url('../fonts/Shabnam.eot?#iefix') format('embedded-opentype'),
       url('../fonts/Shabnam.woff') format('woff'),
       url('../fonts/Shabnam.ttf') format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: ShabnamBold;
  src: url('../fonts/Shabnam-Bold.eot');
  src: url('../fonts/Shabnam-Bold.eot?#iefix') format('embedded-opentype'),
       url('../fonts/Shabnam-Bold.woff') format('woff'),
       url('../fonts/Shabnam-Bold.ttf') format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: ShabnamLight;
  src: url('../fonts/Shabnam-Light.eot');
  src: url('../fonts/Shabnam-Light.eot?#iefix') format('embedded-opentype'),
       url('../fonts/Shabnam-Light.woff') format('woff'),
       url('../fonts/Shabnam-Light.ttf') format('truetype');
  font-weight: 300;
}


// Styles Variable 
$color-1: #5ec3ae;
$color-2: #14447a;
$color-3: #828f97;


$mainTitleSize: 40px;
$paragraphFontSize: 16px;


$GlobalFSize: 13px;
$dateFontSize:116px;
// Heading 
$h1:49px;
$h2:37px;
$h3:30px;
$h4:26px;
$h5:22px;
$h6:19px;
// add responsive size Variables
$XDesktop:1920px;
$ScreenDesktop:1690px;
$MacLaptop:1440px;
$Laptop:1382px;
$largDesktop: 1200px;
$Desktop: 992px;
$Tablets: 768px;
$Phones: 400px;
// responsive use it in class : @include respond-to($media) { CSS: value}
@mixin respond-to($media) {
    @if $media==XDesktop {
        @media (max-width: $XDesktop) and (min-width: $ScreenDesktop) {
            @content;
        }
    }
    @if $media==MacLaptop {
        @media (max-width: $MacLaptop) {
            @content;
        }
    }
    @if $media==Laptop {
        @media (max-width: $Laptop) {
            @content;
        }
    }
    @if $media==largDesktop {
        @media (max-width: $largDesktop) {
            @content;
        }
    }
    @else if $media==Desktop {
        @media (max-width: $Desktop) {
            @content;
        }
    }
    @else if $media==Tablets {
        @media (max-width: $Tablets) {
            @content;
        }
    }
    @else if $media==Phones {
        @media (max-width: $Phones) {
            @content;
        }
    }
}
.bls-1{
    border-left: 1px solid #f1f1f1;
}
.brs-1{
    border-right: 1px solid #f1f1f1;
}
.bxs-1{
    border-left: 1px solid #f1f1f1;
    border-right: 1px solid #f1f1f1;
}
.psa-3{
    border: 3px solid #f2f4f4;
}
.text-gray{
    color: #828f97 !important;
}
// body
    body{
        background: url(../images/bg.jpg);
        font-family: Shabnam;
        font-size: $GlobalFSize;
    }
    h1,h2,h3,h4,h5,h6{
        font-family: ShabnamBold;
    }
    input,a,button,textarea{
        font-family: Shabnam;
    }
    h1{
        font-size: $h1;
    }
    h2{
        font-size: $h2;
    }
    h3{
        font-size: $h3;
    }
    h4{
        font-size: $h4;
    }
    h5{
        font-size: $h5;
    }
    h6{
        font-size: $h6;
    }
    p{
        font-weight: 400;
    }