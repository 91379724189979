footer{
    background: $color-2;
    padding: 80px 15px 0px;
    position: relative;
    &:after{
        content: '';
        background: url(../images/footer-lft.png) no-repeat;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        @include respond-to(Phones) {display: none;}
    }
    &:before{
        content: '';
        background: url(../images/footer-rit.png) no-repeat right top;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        right: 0;
    }
    .footer{
        z-index: 1;
        h4{
            font-size: 17px;
            color: #fff;
            position: relative;
            padding-bottom: 20px;
            z-index: 0;
            &:after{
                content: '';
                height: 1px;
                width: 15px;
                background: $color-1;
                position: absolute;
                bottom: 5px;
                right: 5px;
            }
            &:before{
                content: '';
                height: 1px;
                width: 15px;
                background: $color-1;
                position: absolute;
                bottom: 3px;
                right: 12px;
            }
        }
        span{
            display: block;
            color: #fff;
        }
        .Footersoical{
            text-align: left;
            @include respond-to(Phones) {text-align: center;}
            a{
                display: inline-block;
                font-size: 0;
                margin-right: 5px;
                opacity: .8;
                &.facebook{
                    background: url(../images/fsoical.png) no-repeat -200px 0px;
                    width: 42px;
                    height: 42px;
                }
                &.twitter{
                    background: url(../images/fsoical.png) no-repeat -150px 0px;
                    width: 42px;
                    height: 42px;
                }
                &.youtube{
                    background: url(../images/fsoical.png) no-repeat -100px 0px;
                    width: 42px;
                    height: 42px;
                }
                &.gplus{
                    background: url(../images/fsoical.png) no-repeat -50px 0px;
                    width: 42px;
                    height: 42px;
                }
                &.instagram{
                    background: url(../images/fsoical.png) no-repeat 0px 0px;
                    width: 42px;
                    height: 42px;
                }
            }
        }
                         
    }
    .footerbottom{
        background: rgba(18,59,105,.5);
        position: relative;
        z-index: 1;
        width: 100%;
        color: #fff;
        padding: 20px;
        margin-top: 40px;
        @include respond-to(Phones) {text-align: center;}
        a{
            color: #fff;
            margin-left: 10px;
            display: inline-block;
        }
        .text-left{
            @include respond-to(Phones) {text-align: center !important;margin-top: 20px;}
        }
    }
}